<template>
  <div class="page">
    <Login ref="login" :callback="init" />
    <Navbar title="个人资料" :callback="back" type="SMP" />
    <div class="person">
      <van-row>
        <van-col span="10">
          <van-image width="63" height="83" :src="photoPath"></van-image>
        </van-col>
        <van-col span="14" class="text">
          <div>{{ personMsg }}</div>
          <van-button
            size="mini"
            icon="plus"
            type="primary"
            :color="COLOR"
            @click="certify"
            >修改个人信息</van-button
          >
        </van-col>
      </van-row>
    </div>
    <div class="tips">
      <div class="suc color-m" v-if="verify.result === 'SUC'">
        <van-icon name="completed" /> 资料填写符合要求
        <van-tag
          size="mini"
          class="audit"
          :color="COLOR_M"
          @click="auditApply()"
          ><van-icon name="passed" />&nbsp;审核提醒</van-tag
        >
      </div>
      <div class="fail color-s2" v-if="verify.result === 'FAL'">
        <van-icon name="failure" /> 资料填写不符合要求，请填写
        <span v-if="verify.failedGroup.BAS !== undefined">[基础资料]</span>
        <span v-if="verify.failedGroup.EDU !== undefined">[教育经历]</span>
        <span v-if="verify.failedGroup.FMY !== undefined">[家庭成员]</span>
        <span v-if="verify.failedGroup.ETC !== undefined">[入学资料]</span>
        必填内容
      </div>
    </div>
    <van-divider
      ><van-button
        size="mini"
        icon="arrow-down"
        :color="COLOR_M"
        v-if="displayType === 'BSN'"
        @click="display('ALL')"
        plain
        >显示全部资料</van-button
      ><van-button
        size="mini"
        icon="arrow-down"
        :color="COLOR_S1"
        v-if="displayType === 'ALL'"
        @click="display('BSN')"
        plain
        >显示业务需求资料</van-button
      ></van-divider
    >
    <div class="item" v-if="group.BAS !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >基本资料
          <van-tag plain :color="COLOR_S2" v-if="group.BAS === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.BAS === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            :color="COLOR"
            @click="base"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">{{ personBaseMsg }}</van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.EDU !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >教育经历
          <van-tag plain :color="COLOR_S2" v-if="group.EDU === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.EDU === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button plain size="mini" icon="edit" @click="edu" :color="COLOR"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">
          <div v-html="personEduMsg"></div>
        </van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.WRK !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >工作经历
          <van-tag plain :color="COLOR_S2" v-if="group.WRK === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.WRK === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            :color="COLOR"
            @click="work"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc"
          ><div v-html="personWorkMsg"></div
        ></van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.FMY !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >家庭成员
          <van-tag plain :color="COLOR_S2" v-if="group.FMY === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.FMY === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            :color="COLOR"
            @click="family()"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">
          <div class="color-s2" v-if="group.ETC !== undefined">
            注：办理上学业务必须填写学生父母信息
          </div>
          <div v-html="personFamilyMsg" v-else></div>
        </van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.MLF !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >婚姻生活
          <van-tag plain :color="COLOR_S2" v-if="group.MLF === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.MLF === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            :color="COLOR"
            @click="mLife"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">{{ personBaseMsg }}</van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.ETC !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >入学资料
          <van-tag plain :color="COLOR_S2" v-if="group.ETC === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.ETC === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            :color="COLOR"
            @click="entrance"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">{{ personEntranceMsg }}</van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.SKL !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >职业技能
          <van-tag plain :color="COLOR_S2" v-if="group.SKL === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.SKL === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            :color="COLOR"
            @click="skill()"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">
          <div v-html="personSkillMsg"></div>
        </van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.CRT !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >技能证书
          <van-tag plain :color="COLOR_S2" v-if="group.CRT === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.CRT === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            @click="cert()"
            :color="COLOR"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">
          <div v-html="personCertMsg"></div>
        </van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.PJT !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >项目经验
          <van-tag plain :color="COLOR_S2" v-if="group.PJT === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.PJT === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            :color="COLOR"
            @click="project"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc"
          ><div v-html="personProjectMsg"></div>
        </van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.HNR !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >个人荣誉
          <van-tag plain :color="COLOR_S2" v-if="group.HNR === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.HNR === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            @click="honor()"
            :color="COLOR"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">
          <div v-html="personHonorMsg"></div>
        </van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.PTO !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >个人相册
          <van-tag plain :color="COLOR_S2" v-if="group.PTO === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.PTO === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            :color="COLOR"
            @click="photo"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">{{ personPhotoMsg }}</van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.AST !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >收入资产
          <van-tag plain :color="COLOR_S2" v-if="group.AST === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.AST === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            :color="COLOR"
            @click="asset()"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">
          <div v-html="personAssetMsg"></div>
        </van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.HOS !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >房产信息
          <van-tag plain :color="COLOR_S2" v-if="group.HOS === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.HOS === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            :color="COLOR"
            @click="house()"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">
          <div v-html="personHouseMsg"></div>
        </van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.CAR !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >车辆信息
          <van-tag plain :color="COLOR_S2" v-if="group.CAR === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.CAR === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            :color="COLOR"
            @click="car()"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">
          <div v-html="personCarMsg"></div>
        </van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.HBY !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >个人爱好
          <van-tag plain :color="COLOR_S2" v-if="group.HBY === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.HBY === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            :color="COLOR"
            @click="hobby()"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">
          <div v-html="personHobbyMsg"></div>
        </van-col>
      </van-row>
    </div>
    <div class="item" v-if="group.HBT !== undefined">
      <van-row>
        <van-col span="12" class="title"
          >生活习惯
          <van-tag plain :color="COLOR_S2" v-if="group.HBT === 'MDR'"
            >必填</van-tag
          ><van-tag plain :color="COLOR_S1" v-if="group.HBT === 'OTN'"
            >选填</van-tag
          ></van-col
        >
        <van-col span="12" class="btn"
          ><van-button
            plain
            size="mini"
            icon="edit"
            :color="COLOR"
            @click="habit()"
            >编辑</van-button
          ></van-col
        >
      </van-row>
      <van-row>
        <van-col span="24" class="desc">
          <div v-html="personHabitMsg"></div>
        </van-col>
      </van-row>
    </div>
    <!-- <div class="operate">
      <van-row>
        <van-col span="24">
          <van-button
            class="btn"
            icon="eye-o"
            :color="COLOR"
            size="small"
            @click="personView()"
            >资料预览</van-button
          >
        </van-col>
      </van-row>
    </div> -->
    <Share ref="share" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Icon, Tag, Uploader, Image, Divider } from 'vant'
import Login from '../../intercept/Login.vue'
import Share from '../../common/Share.vue'
import Navbar from '../../common/Navbar.vue'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Login: Login,
    Share: Share,
    Navbar: Navbar,
    Loading: Loading,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Uploader.name]: Uploader,
    [Image.name]: Image,
    [Divider.name]: Divider
  },
  data () {
    return {
      code: '',
      loginState: 'N',
      loadingShow: false,
      personCode: '',
      inviterCode: '',
      business: '',
      profile: '',
      verify: { result: 'WAT' },
      group: '',
      displayType: 'BSN',
      photoImages: [],
      photoPath: '',
      auditState: 'Y',
      personMsg: '个人资料,请点击编辑进行修改',
      personBaseMsg: '个人基本信息,请点击编辑新增',
      personEntranceMsg: '入学资料信息,请点击编辑新增',
      personSkillMsg: '个人技能信息,请点击编辑新增',
      personCertMsg: '个人证书信息,请点击编辑新增',
      personProjectMsg: '个人项目信息,请点击编辑新增',
      personHonorMsg: '个人荣誉信息,请点击编辑新增',
      personEduMsg: '个人教育信息,请点击编辑新增',
      personWorkMsg: '个人工作信息,请点击编辑新增',
      personFamilyMsg: '个人家庭信息,请点击编辑新增',
      personAssetMsg: '收入资产信息,请点击编辑新增',
      personHouseMsg: '个人房产信息,请点击编辑新增',
      personCarMsg: '个人车辆信息,请点击编辑新增',
      personHobbyMsg: '个人爱好信息,请点击编辑新增',
      personHabitMsg: '生活习惯信息,请点击编辑新增',
      personPhotoMsg: '个人相册信息,请点击编辑新增'
    }
  },
  mounted () {
    var query = this.$route.query
    var inviterCode = query.inviterCode
    if (inviterCode !== undefined && inviterCode !== '') {
      this.inviterCode = inviterCode
    }
    var personCode = query.personCode
    if (personCode !== undefined && personCode !== '') {
      this.personCode = personCode
    }
    this.business = query.business
    this.profile = query.profile
    this.$refs.login.validate()
  },
  methods: {
    init () {
      this.retrievePerson()
      this.display('BSN')
    },
    back () {
      var key = window.const.global.PEOPLE_PERSON_DTLEDIT_BACK
      var url = window.sessionStorage.getItem(key)
      window.location.href = url
    },
    certify () {
      this.$router.push({ path: '/mde/people/person/dtlCertify', query: { code: this.personCode, module: 'RSM', inviterCode: this.inviterCode } })
    },
    base () {
      this.$router.push({ path: '/mde/people/person/dtlBase', query: { personCode: this.personCode, profile: this.profile } })
    },
    mLife () {
      this.$router.push({ path: '/mde/people/person/dtlMLife', query: { personCode: this.personCode } })
    },
    entrance () {
      this.$router.push({ path: '/mde/people/person/dtlEntrance', query: { personCode: this.personCode } })
    },
    skill () {
      this.$router.push({ path: '/mde/people/person/dtlSkill', query: { personCode: this.personCode } })
    },
    cert () {
      this.$router.push({ path: '/mde/people/person/dtlCert', query: { personCode: this.personCode } })
    },
    project () {
      this.$router.push({ path: '/mde/people/person/dtlProject', query: { personCode: this.personCode } })
    },
    honor () {
      this.$router.push({ path: '/mde/people/person/dtlHonor', query: { personCode: this.personCode } })
    },
    edu () {
      this.$router.push({ path: '/mde/people/person/dtlEdu', query: { personCode: this.personCode } })
    },
    work () {
      this.$router.push({ path: '/mde/people/person/dtlWork', query: { personCode: this.personCode } })
    },
    house () {
      this.$router.push({ path: '/mde/people/person/dtlHouse', query: { personCode: this.personCode } })
    },
    family () {
      this.$router.push({ path: '/mde/people/person/dtlFamily', query: { personCode: this.personCode } })
    },
    asset () {
      this.$router.push({ path: '/mde/people/person/dtlAsset', query: { personCode: this.personCode } })
    },
    car () {
      this.$router.push({ path: '/mde/people/person/dtlCar', query: { personCode: this.personCode } })
    },
    hobby () {
      this.$router.push({ path: '/mde/people/person/dtlHobby', query: { personCode: this.personCode } })
    },
    habit () {
      this.$router.push({ path: '/mde/people/person/dtlHabit', query: { personCode: this.personCode } })
    },
    photo () {
      this.$router.push({ path: '/mde/people/person/dtlPhoto', query: { personCode: this.personCode } })
    },
    personView () {
      this.$router.push({ path: '/mde/person/resumeView', query: { personCode: this.personCode } })
    },
    async retrievePerson () {
      var pd = { code: this.personCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePerson', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.photoPath = res.data.photoPath
        this.personMsg = res.data.name + ' | ' + res.data.phone + ' | ' + res.data.identity
        this.verifyPersonProfile()
      }
    },
    async verifyPersonProfile () {
      var pd = { personCode: this.personCode, profileKey: this.profile }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/verifyPersonProfile', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.verify = res.data
        console.log(res.data)
      }
    },
    display (type) {
      this.displayType = type
      if (type === 'ALL') {
        this.group = { BAS: 'OTN', CRT: 'OTN', EDU: 'OTN', FMY: 'OTN', PJT: 'OTN', SKL: 'OTN', WRK: 'OTN', ETC: 'OTN', AST: 'OTN', HOS: 'OTN', CAR: 'OTN', HBY: 'OTN', HBT: 'OTN', MLF: 'OTN', PTO: 'OTN', HNR: 'OTN' }
      } else {
        this.retrieveProfileAttrConfig()
        // if (this.business === 'RCT') {
        //   this.groups = 'BAS,CRT,EDU,FMY,PJT,SKL,WRK,PTO,HNR'
        // } else if (this.business === 'TRA') {
        //   this.groups = 'BAS,CRT'
        // } else if (this.business === 'ERO') {
        //   this.groups = 'BAS,ETC,FMY'
        // } else if (this.business === 'LOV') {
        //   this.groups = 'BAS,WRK,AST,HOS,CAR,HBY,HBT,MLF,PTO'
        // }
      }
    },
    async retrieveProfileAttrConfig () {
      this.loadingShow = true
      var pd = { arg: this.profile, mode: 'KEY' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/profile/retrieveProfileAttrConfig', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.group = res.data.group
        window.sessionStorage.setItem(window.const.global.PEOPLE_PROFILE_ATTR, JSON.stringify(res.data.attr))
      }
      this.loadingShow = false
    },
    async auditApply () {
      var audit = window.sessionStorage.getItem('person_audit_state')
      if (audit === 'N') {
        this.$dialog.alert({
          title: '提示信息',
          message: '已提交审核,请勿重复提交'
        })
      } else {
        var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
        var pd = { personCode: this.personCode, sellerCode: seller }
        const { data: res } = await this.$http.post(this.BMS_URL + '/member/person/personAuditApply', this.$qs.stringify(pd))
        if (res.status === '200') {
          window.sessionStorage.setItem('person_audit_state', 'N')
          this.$dialog.alert({
            title: '提示信息',
            message: '提交成功，请等待审核'
          })
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  padding-bottom: 49px;
  .person {
    padding-top: 10px;
    .text {
      padding-top: 10px;
      text-align: center;
      height: 20px;
      line-height: 20px;
    }
  }
  .tips {
    margin-top: 10px;
    text-align: center;

    width: 100%;
    line-height: 20px;
    font-weight: 800;
    .suc {
      padding: 5px 10px;
      background-color: #def7f1;
    }
    .fail {
      padding: 5px 10px;
      background-color: #ffe7e5;
    }
    .audit {
      padding: 2px 8px;
    }
  }
  .item {
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    .title {
      font-size: 15px;
      height: 20px;
      line-height: 20px;
      font-weight: 600;
      margin: 10px 0px 10px 0px;
      padding-left: 10px;
      text-align: left;
    }
    .desc {
      text-align: left;
      padding-left: 10px;
    }
    .btn {
      height: 20px;
      line-height: 20px;
      margin: 10px 0px 0px 0px;
      padding-right: 10px;
      text-align: right;
    }
  }
}
.operate {
  padding: 5px 0px;
  background-color: #fff;
  height: 40px;
  position: fixed;
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  bottom: 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #bbb;
}
</style>
